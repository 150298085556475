<script setup>
import { reactive, ref } from "vue-demi";
import { Search, Document } from '@element-plus/icons-vue'


const data = reactive({
  searchInfo: {},
  searchData: [
    // {
    //   ac_company: "",
    //   accept_id: "",
    //   ag_company: "浙江蓝帆网络科技有限公司",
    //   agent_id: "ZJ_ls_01",
    //   b_company: "",
    //   bank_address: "浙江泰隆商业银行股份有限公司上海青浦支行",
    //   branch_id: "",
    //   card: "***************0570",
    //   card_name: "王君",
    //   channel: "ruiyinxin",
    //   channel_org: "快银",
    //   channel_str: "瑞银信",
    //   id: "1398272",
    //   insert_time: "2022-12-02",
    //   k: 0,
    //   money: 48894.76,
    //   partner_id: "parent_channel_5353",
    //   payment_fail_msg: "交易成功",
    //   payment_no: "29000000006171620221130555",
    //   payment_status: 1,
    //   payment_status_str: "成功",
    //   payment_time: "2022-12-01",
    //   remark_msg: "",
    //   shop_account: "9beff81751d6f56197bef8a4c8ac9690",
    //   shop_id: "9beff81751d6f56197bef8a4c8ac9690",
    //   shop_name: "上海浙扬超市有限公司（美惠全江川店）有事联系叶：13735926661",
    //   start_time: "2022-11-30",
    //   trueid: "Ve_bzH_pLwtzl",
    //   ywy_name: "陆家俊",
    //   ywy_openid: "os5_ywGsM1uR98QFIGVLipBr08Ho",
    //   p_company: ""
    // },
    // {
    //   ac_company: "",
    //   accept_id: "",
    //   ag_company: "浙江蓝帆网络科技有限公司",
    //   agent_id: "ZJ_ls_01",
    //   b_company: "",
    //   bank_address: "浙江泰隆商业银行股份有限公司上海青浦支行",
    //   branch_id: "",
    //   card: "***************0570",
    //   card_name: "王君",
    //   channel: "ruiyinxin",
    //   channel_org: "快银",
    //   channel_str: "瑞银信",
    //   id: "1398272",
    //   insert_time: "2022-12-02",
    //   k: 0,
    //   money: 48894.76,
    //   partner_id: "parent_channel_5353",
    //   payment_fail_msg: "交易失败",
    //   payment_no: "29000000006171620221130555",
    //   payment_status: 1,
    //   payment_status_str: "失败",
    //   payment_time: "2022-12-01",
    //   remark_msg: "",
    //   shop_account: "9beff81751d6f56197bef8a4c8ac9690",
    //   shop_id: "9beff81751d6f56197bef8a4c8ac9690",
    //   shop_name: "上海浙扬超市有限公司（美惠全江川店）有事联系叶：13735926661",
    //   start_time: "2022-11-30",
    //   trueid: "Ve_bzH_pLwtzl",
    //   ywy_name: "陆家俊",
    //   ywy_openid: "os5_ywGsM1uR98QFIGVLipBr08Ho",
    //   p_company: ""
    // },
    // {
    //   ac_company: "",
    //   accept_id: "",
    //   ag_company: "浙江蓝帆网络科技有限公司",
    //   agent_id: "ZJ_ls_01",
    //   b_company: "",
    //   bank_address: "浙江泰隆商业银行股份有限公司上海青浦支行",
    //   branch_id: "",
    //   card: "***************0570",
    //   card_name: "王君",
    //   channel: "ruiyinxin",
    //   channel_org: "快银",
    //   channel_str: "瑞银信",
    //   id: "1398272",
    //   insert_time: "2022-12-02",
    //   k: 0,
    //   money: 48894.76,
    //   partner_id: "parent_channel_5353",
    //   payment_fail_msg: "交易结算中",
    //   payment_no: "29000000006171620221130555",
    //   payment_status: 1,
    //   payment_status_str: "结算中",
    //   payment_time: "2022-12-01",
    //   remark_msg: "",
    //   shop_account: "9beff81751d6f56197bef8a4c8ac9690",
    //   shop_id: "9beff81751d6f56197bef8a4c8ac9690",
    //   shop_name: "上海浙扬超市有限公司（美惠全江川店）有事联系叶：13735926661",
    //   start_time: "2022-11-30",
    //   trueid: "Ve_bzH_pLwtzl",
    //   ywy_name: "陆家俊",
    //   ywy_openid: "os5_ywGsM1uR98QFIGVLipBr08Ho",
    //   p_company: ""
    // },
    // {
    //   ac_company: "",
    //   accept_id: "",
    //   ag_company: "乾乾网络科技",
    //   agent_id: "agent_7013_350788",
    //   b_company: "江苏三达智通广东分公司",
    //   bank_address: "中国建设银行股份有限公司南京翠屏国际支行",
    //   branch_id: "",
    //   card: "***************5498",
    //   card_name: "王君",
    //   channel: "ruiyinxin",
    //   channel_org: "快银",
    //   channel_str: "瑞银信",
    //   id: "1400832",
    //   insert_time: "2022-12-04",
    //   k: 0,
    //   money: 12834.44,
    //   p_company: "北京智新海诚科技有限公司",
    //   partner_id: "parent_channel_5294",
    //   payment_fail_msg: "交易成功",
    //   payment_no: "30000000083724820221202555",
    //   payment_status: 1,
    //   payment_status_str: "成功",
    //   payment_time: "2022-12-03",
    //   remark_msg: "",
    //   shop_account: "2f4c079c0894408e36716cb0fdf0c772",
    //   shop_id: "2f4c079c0894408e36716cb0fdf0c772",
    //   shop_name: "王君",
    //   start_time: "2022-12-02",
    //   trueid: "y0_QC4_sMpxxq",
    //   ywy_name: "潘剑斌",
    //   ywy_openid: "os5_ywDaLtcguJB5qMVQ4r4Mz_NE",
    // }
  ],
  pageinfo: {
    total: 1,
    perPage: 15,
    currentPage: 1
  },
  remarckData: {
    remarck: ""
  }
})

const c_data = {
  s_width: "width:200px"
}

const pageChange = (page) => {
  console.log(page);
}

const option = reactive({
  partners: [],
  accept: [],
  settle: [
    {
      state: 0,
      text: "全部"
    },
    {
      state: 1,
      text: "结算中"
    },
    {
      state: 2,
      text: "成功"
    },
    {
      state: 3,
      text: "失败"
    },
  ],
  channel: [
    { company: "南通优杰科技有限公司", channelId: "nantong" },
    { company: "陈超", channelId: "ky_cc" },
    { company: "浙江贤喆网络科技有限公司", channelId: "ky_sxp" },
    { company: "王仁泽", channelId: "ky_ws" },
    { company: "常卫华", channelId: "ningxia" },
    { company: "淮安市江山光电科技有限公司", channelId: "parent_channel_1184" },
    { company: "标准吸粉专用渠道", channelId: "parent_channel_1332" },
    { company: "山东昊坤大数据有限公司", channelId: "parent_channel_1567" }
  ]
})

const handleSearch = () => {
  let info = data.searchInfo
  if (Object.keys(info).length == 0) {
    queryData()
  } else {
    if (info.datetime != null) {
      info.created_start = info.datetime[0]
      info.created_end = info.datetime[1]
      queryData(info)
    } else {
      delete info.created_start
      delete info.created_end
      queryData(info)
    }
  }
}

const queryData = (d) => {
  console.log(d);
}

const handleRemark = (row) => {
  console.log(row);
  remarckIsShwo.value = true
}

const remarckIsShwo = ref(false)


const hanldeExportExcel = () => {
  console.log("导出");
}
</script>


<template>
  <el-card shadow="never">
    <div class="top-search">
      <el-form :inline="true" :model="data.searchInfo" label-width="100px" label-position="right">
        <el-row>
          <el-col :span="6">
            <el-form-item label="商户号：">
              <el-input v-model="data.searchInfo.merchant_no" placeholder="请输入商户号" :style=c_data.s_width />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="商户名称：">
              <el-input v-model="data.searchInfo.merchant_name" placeholder="请输入商户名称" :style=c_data.s_width />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="结算状态：">
              <el-select v-model="data.searchInfo.state" placeholder="请选择合作商" :style=c_data.s_width clearable>
                <el-option v-for="(item,index) in option.settle" :key="index" :label="item.text" :value="item.state" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="大渠道:">
              <el-select v-model="data.searchInfo.channel" filterable placeholder="请选择大渠道" :style=c_data.s_width clearable>
                <el-option v-for="(item,index) in option.channel" :key="index" :label="item.company" :value="item.channelId" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="合作商：">
              <el-select v-model="data.searchInfo.partners" filterable placeholder="请选择合作商" clearable :style=c_data.s_width>
                <el-option v-for="(item,index) in option.partners" :key="index" :label="item.name" :value="item.id" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="受理商：">
              <el-select v-model="data.searchInfo.accept" filterable placeholder="请选择受理商" clearable :style=c_data.s_width>
                <el-option v-for="(item,index) in option.accept" :key="index" :label="item.name" :value="item.id" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="结算时间：">
              <el-date-picker v-model="data.searchInfo.datetime" value-format="YYYY-MM-DD HH:mm:ss" type="datetimerange" range-separator="至" start-placeholder="起始时间" end-placeholder="结束时间" />
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <div class="btn-box">
              <el-button type="primary" @click="handleSearch()" :icon="Search">查询</el-button>
            </div>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </el-card>
  <div class="mian">
    <div class="export">
      <el-button type="success" link :icon="Document" @click="hanldeExportExcel">导出Excel</el-button>
    </div>
    <el-table :data="data.searchData" style="width: 100%" size="small">
      <el-table-column prop="shop_account" label="商户号" width="100">
        <template #default="scope">
          <el-tooltip :content=scope.row.shop_account placement="top">
            <span>{{scope.row.shop_account.substr(0,9)+'...'}}</span>
            <el-icon>
              <CopyDocument @click="handleCopy(scope.row.shop_account)" style="color:#52acff" />
            </el-icon>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="shop_name" label="商户名" width="160">
        <template #default="scope">
          <el-tooltip :content=scope.row.shop_name placement="top">
            <span>{{scope.row.shop_name.substr(0,9)+'...'}}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="channel_str" label="结算通道" width="80" />
      <el-table-column prop="money" label="结算金额" />
      <el-table-column prop="bank_address" label="银行地址" width="100">
        <template #default="scope">
          <el-tooltip :content=scope.row.bank_address placement="top">
            <span>{{scope.row.bank_address.substr(0,6)+'...'}}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="card_name" label="户主" />
      <el-table-column prop="card" label="结算卡号" width="140" />
      <el-table-column prop="start_time" label="交易日期" width="100" />
      <el-table-column prop="payment_time" label="结算日期" width="100" /> -->
      <el-table-column prop="payment_status_str" label="结算状态" width="80">
        <template #default="scope">
          <el-tag type="success" v-if="scope.row.payment_status_str=='成功'">{{scope.row.payment_status_str}}</el-tag>
          <el-tag type="danger" v-else-if="scope.row.payment_status_str=='失败'">{{scope.row.payment_status_str}}</el-tag>
          <el-tag type="warning" v-else>{{scope.row.payment_status_str}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="payment_fail_msg" label="结算原因" width="80" />
      <el-table-column prop="ywy_name" label="业务员" width="80" />
      <el-table-column prop="ac_company" label="受理商">
        <template #default="scope">
          <div v-if="(scope.row.ac_company==null || scope.row.ac_company=='')">
            <span></span>
          </div>
          <el-tooltip v-else :content=scope.row.ac_company placement="top">
            <span>{{scope.row.ac_company.substr(0,6)+'...'}}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="ag_company" label="合作商" width="120">
        <template #default="scope">
          <el-tooltip :content=scope.row.ag_company placement="top">
            <span>{{scope.row.ag_company.substr(0,6)+'...'}}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="p_company" label="大渠道" width="80">
        <template #default="scope">
          <div v-if="(scope.row.p_company==null || scope.row.p_company=='')">
            <span> </span>
          </div>
          <el-tooltip v-else :content=scope.row.p_company placement="top">
            <span>{{scope.row.p_company.substr(0,4)+'...'}}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="b_company" label="分公司" width="80">
        <template #default="scope">
          <div v-if="(scope.row.b_company==null || scope.row.b_company=='')">
            <span></span>
          </div>
          <el-tooltip v-else :content=scope.row.b_company placement="top">
            <span>{{scope.row.b_company.substr(0,4)+'...'}}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="channel_org" label="所属通道" width="80" />
      <el-table-column prop="remark_msg" label="备注" />
      <el-table-column label="操作">
        <template #default="scope">
          <el-button link type="primary" @click="handleRemark(scope.row)">备注</el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-empty description="空数据" :image-size="200" />
      </template>
    </el-table>
    <div class="paging">
      <el-pagination background layout="total, prev, pager, next" :total=data.pageinfo.total @current-change="pageChange" :page-size=data.pageinfo.perPage :current-page=data.pageinfo.currentPage />
    </div>

    <el-dialog v-model="remarckIsShwo" title="备注信息" width="20%">
      <el-form :model="data.remarckData">
        <el-form-item label="备注">
          <el-input v-model="data.remarckData.remarck" type="textarea" />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="remarckIsShwo = false">取消</el-button>
        <el-button type="primary" @click="remarckIsShwo = false">确认</el-button>
      </template>
    </el-dialog>

  </div>
</template>

<style lang="less" scoped>
.top-search {
  width: 100%;
  .btn-box {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}

.mian {
  padding: 6px 0;
  .paging {
    margin: 1.25rem 0;
    display: flex;
    justify-content: flex-end;
  }
  .export {
    display: flex;
    justify-content: flex-end;
    padding: 10px 0;
  }
}
</style>